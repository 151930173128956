// network events
export const ERROR_EVENT = "ERROR"
export const CHAT_EVENT = "CHAT"
export const CHAT_ALL_EVENT = "CHAT_ALL"
export const AUTH_EVENT = "AUTH"
export const PLAYER_EVENT = "PLAYER"
export const SERVER_LOG_EVENT = "SERVER_LOG"
export const ROOM_UPDATE_EVENT = "ROOM_UPDATE"
export const ROLL_EVENT = "ROLL"
export const MAKE_ROOM_EVENT = "MAKE_ROOM"
export const GO_EVENT = "GO"
export const USERNAME_CHANGE_EVENT = "USERNAME_CHANGE"
export const PING_EVENT = "PING"
export const PONG_EVENT = "PONG"
export const CHAT_HISTORY_EVENT = "CHAT_HISTORY"
export const LOOK_EVENT = "LOOK"
export const DRAW_EVENT = "DRAW"
export const MAKE_DOOR_EVENT = "MAKE_DOOR"
export const TP_EVENT = "TP"
export const DELETE_DOOR_EVENT = "DELETE_DOOR"
export const ROOM_DESCRIBE_EVENT = "DESCRIBE"
export const SHOUT_EVENT = "SHOUT"
export const WHISPER_EVENT = "WHISPER"
export const ME_EVENT = "ME"
export const ONLINE_EVENT = "ONLINE"
export const MAKE_ITEM_EVENT = "MAKE_ITEM"
export const INV_EVENT = "INV"
export const DROP_ITEM_EVENT = "DROP_ITEM"
export const TAKE_ITEM_EVENT = "TAKE_ITEM"
export const LOOK_AT_EVENT = "LOOK_AT"
export const PAY_EVENT = "PAY"
export const INBOX_EVENT = "INBOX"
export const INBOX_HISTORY_EVENT = "INBOX_HISTORY"
export const WHISPER_LOG_EVENT = "WHISPER_LOG"
export const NOTIFICATION_EVENT = "NOTIFICATION"
export const COMMAND_LOG_EVENT = "COMMAND_LOG"
export const SEND_EVENT = "SEND"
export const REPLY_EVENT = "REPLY"
export const HISTORY_EVENT = "HISTORY"
export const USE_ITEM_EVENT = "USE_ITEM"
export const ENCHANT_ITEM_EVENT = "ENCHANT_ITEM"
export const PAY_USER_EVENT = "PAY_USER"
export const HELP_EVENT = "HELP"
export const HELP_AT_EVENT = "HELP_AT"
export const REFRESH_EVENT = "REFRESH"
export const COMPOSE_EVENT = "COMPOSE"
export const CHANGE_MUSIC_EVENT = "CHANGE_MUSIC"
export const MUSIC_UPDATE_EVENT = "MUSIC_UPDATE"
export const MUSIC_EVENT = "MUSIC"
export const UPDATE_BANNER_EVENT = "UPDATE_BANNER"
export const INVITE_EVENT = "INVITE"
export const GAME_UPDATE_EVENT = "GAME_UPDATE"
export const GAME_EVENT = "GAME"
export const BANNER_UPDATE_EVENT = "BANNER_UPDATE"
export const CLICK_EVENT = "CLICK"
export const CHANGE_BANNER_EVENT = "CHANGE_BANNER"
export const TOOLBAR_UPDATE_EVENT = "TOOLBAR_UPDATE"
export const DOOR_UPDATE_EVENT = "DOOR_UPDATE"
export const MAKE_POST_EVENT = "MAKE_POST"
export const LINK_EVENT = "LINK"
export const EVENT_EVENT = "EVENT"
export const EVENT_UPDATE_EVENT = "EVENT_UPDATE"
export const SMELT_ITEM_EVENT = "SMELT_ITEM"
export const CAMPAIGN_LOG_EVENT = "CAMPAIGN_LOG"
export const UFO_EVENT = "UFO"
export const INVENTORY_UPDATE_EVENT = "INVENTORY_UPDATE"
export const DRAW_ICON_EVENT = "DRAW_ICON"
export const INSIGHT_EVENT = "INSIGHT"
export const MAKE_EVENT = "MAKE"
export const NPC_UPDATE_EVENT = "NPC_UPDATE"
export const DRAW_AVATAR_EVENT = "DRAW_AVATAR"
export const TAG_ITEM_EVENT = "TAG_ITEM"
export const INBOX_UPDATE_EVENT = "INBOX_UPDATE"
export const CORRESPONDENTS_UPDATE_EVENT = "CORRESPONDENTS_UPDATE"
export const MORE_INBOX_EVENT ="MORE_INBOX"
export const WHISPER_POPUP_EVENT = "WHISPER_POPUP"
export const LOOK_LOG_EVENT = "LOOK_LOG"
export const DRAW_COLOR_EVENT = "DRAW_COLOR"
export const DRAW_BACK_COLOR_EVENT = "DRAW_BACK_COLOR_EVENT"
export const ACTIVE_UPDATE_EVENT = "ACTIVE_UPDATE"
export const RANDOM_ROOM_EVENT = "RANDOM_ROOM"
export const HOME_UPDATE_EVENT = "HOME_UPDATE"

// command events
export const LOG_EVENT = "LOG"
export const INPUT_EVENT = "INPUT"
